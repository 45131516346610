<template>
    <div class="legend">
        <v-chip @click="search(item)"
            v-for="(item, index) in legends"
            :key="index"
            class="legend-chip"
            color="grey lighten-3"
            dark
        >
            <hourglass v-if="item.text.includes(constantsMigration.WAITING_TO_WIPE_STATUS)"/>
            <bin-mdm-status v-else-if="item.text.includes(constantsMigration.WIPED_STATUS)"/>
            <check-circle v-else-if="item.text.includes(constantsMigration.ENROLLED_WSO_STATUS)"/>
            <close-circle v-else-if="item.text.includes(constantsMigration.NOT_ENROLLED_WSO_STATUS)"/>
            {{ item.text }}
        </v-chip>
        <v-chip :color="constantsMigration.COLORS.BLUE_BRAND" :text-color="constantsMigration.COLORS.WHITE" @click="resetAndSearch()">Display all</v-chip>
    </div>
</template>

<script>
import Hourglass from "../icons/hourglass-icon.vue";
import CheckCircle from "../icons/check-circle-icon.vue";
import BinMdmStatus from "../icons/bin-mdm-status-icon.vue";
import CloseCircle from "../icons/close-circle-icon.vue";
import constantsMigration from "../constants-migration";

export default {
    name: 'Legend',
    components: {CloseCircle, BinMdmStatus, CheckCircle, Hourglass},
    data() {
        return {
            legends: [
                { text: 'Waiting to wipe on MDM' },
                { text: 'Wiped on MDM' },
                { text: 'Enrolled on WSO' },
                { text: 'Not on WSO' },
            ],
            statusFilter: {},
            constantsMigration
        };
    },
    methods: {
        resetStatusFilter() {
            this.statusFilter = {
                mdm: {
                    isNotWiped: false,
                    isWiped: false,
                },
                wso: {
                    isNotInWso: false,
                    isInWso: false
                }
            }
        },
        search(item) {
            this.resetStatusFilter();
            if (item?.text.includes(constantsMigration.WAITING_TO_WIPE_STATUS)) {
                this.statusFilter.mdm.isNotWiped = true;
            } else if (item?.text.includes(constantsMigration.WIPED_STATUS)) {
                this.statusFilter.mdm.isWiped = true;
            } else if (item?.text.includes(constantsMigration.ENROLLED_WSO_STATUS)) {
                this.statusFilter.wso.isInWso = true;
            } else if (item?.text.includes(constantsMigration.NOT_ENROLLED_WSO_STATUS)) {
                this.statusFilter.wso.isNotInWso = true;
            }

            this.$emit("startSearch", this.statusFilter);
        },
        resetAndSearch() {
            this.resetStatusFilter();
            this.$emit("resetAndSearch", this.statusFilter);
        }
    }
};
</script>

<style scoped>
.legend {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    margin-bottom: 15px;
}

.legend-chip {
    margin-bottom: 5px;
    color: black;
}
</style>
